import createStore from 'zustand/vanilla';
import { removeController, updateController } from './controller';
import {
  Dependent,
  GraphNode,
  NatureDefinition,
  PropertyAccess,
  RemoveNodeResponse,
  SystemObject,
  SystemObjectData,
  SystemObjectDetails,
  SystemObjectRef,
  SystemObjectResult,
} from './types';
import { deepCompare, transformProperties } from './utils';

export interface Requester {
  query<T>(op: string, args: any, signal?: AbortSignal): Promise<T>;
  mutate<T>(op: string, args: any): Promise<T>;
}

const roots = new Set<number>();
const requester: Requester = {
  mutate() {
    return Promise.reject('No requester configured.');
  },
  query() {
    return Promise.reject('No requester configured.');
  },
};

export const natures: Array<NatureDefinition> = [];

export const nodes = createStore(() => ({} as Record<string, GraphNode>));

/**
 * Configures the requester used for making API calls
 * @param newRequester The new requester to be used
 */
export function configureRequester(newRequester: Requester) {
  Object.assign(requester, newRequester);
}

/**
 * Compares two system objects for sorting based on their names
 */
function sortNodes(a: SystemObject, b: SystemObject) {
  let nameA = '',
    nameB = '';

  if (a.name && b.name) {
    nameA = a.name;
    nameB = b.name;
  } else {
    nameA = (a.properties?.find((p) => p.label === 'name')?.value as string) || '';
    nameB = (b.properties?.find((p) => p.label === 'name')?.value as string) || '';
  }

  return nameA.toLocaleLowerCase().localeCompare(nameB.toLocaleLowerCase());
}

/**
 * Constructs a system object by transforming properties and recursively handling children
 */
function constructSystemObject(so: SystemObject): SystemObject {
  const item = {
    ...so,
    ...transformProperties(so?.properties ?? []),
    children: so?.children?.map(constructSystemObject) ?? [],
    parents: so?.parents ?? [],
  };

  return item;
}

/**
 * Initializes the graph with roots and nature definitions
 * @param objectRoots Array of system objects to be used as roots
 * @param initialNatures Array of nature definitions to initialize
 */
export function initializeGraph(objectRoots: Array<SystemObject>, initialNatures: Array<NatureDefinition>) {
  const rootNodes = objectRoots.map(toNode);
  natures.push(...initialNatures);
  rootNodes.forEach((r) => roots.add(r.id));
  updateNodes(rootNodes);
}

/**
 * Converts a system object to a graph node
 */
function toNode(item: SystemObject): GraphNode {
  const details = constructSystemObject(item);

  return {
    id: item.id,
    childNodes: item?.children?.sort(sortNodes)?.map((m) => m.id) ?? [],
    facts: {},
    loaded: false,
    loading: false,
    deleted: false,
    details,
  };
}

/**
 * Updates nodes in the graph state
 * @param newNodes Array of graph nodes to update
 */
function updateNodes(newNodes: Array<GraphNode>) {
  const snapshot = nodes.getState();
  const update: Record<string, GraphNode> = {};
  const queue = [...newNodes];

  while (queue.length > 0) {
    const node = queue.pop();

    if (node) {
      const existing = snapshot[node.id];

      if (!existing || newNodes.includes(node)) {
        update[node.id] = node;
      } else if (existing && !existing.loaded) {
        update[node.id] = {
          ...existing,
          details: {
            ...existing.details,
            children: node.details?.children || existing.details.children,
          },
          childNodes: node.childNodes || existing.childNodes,
        };
      }

      const children = node?.details?.children?.map(toNode) ?? [];

      queue.push(...children);
    }
  }

  nodes.setState(update);
}

/**
 * Updates a node in its parent objects' children arrays
 * @param id ID of the node to update
 * @param details Updated system object details
 */
function updateNodeInParents(id: number, details: SystemObject) {
  if (typeof id !== 'number' || !details) return;

  const parents = details?.parents || [];
  for (const parent of parents) {
    updateNode(parent.id, (n) => {
      const children = n?.details?.children?.map((c) => {
        if (c.id === id) {
          return { ...c, ...details };
        }
        return c;
      });

      return {
        details: {
          ...n.details,
          children,
        },
      };
    });
  }
}

/**
 * Resyncs a node by fetching fresh data and updating it in the store
 * @param id ID of the node to resync
 */
async function resyncNode(id: number) {
  const { object: result } = await queryNode(id).catch(() => ({} as SystemObjectData));

  // Update the current node
  updateNode(id, (node) => ({
    ...node,
    details: constructSystemObject(result),
  }));

  // Update the current node in parent objects (children property)
  updateNodeInParents(id, result);
}

/**
 * Updates a specific node by applying a function to its current state
 * @param id ID of the node to update
 * @param fn Function that takes the current node and returns partial updates
 */
function updateNode(id: number, fn: (node: GraphNode) => Partial<GraphNode>) {
  const state = nodes.getState();
  const oldItem = state[id];
  const newItem = fn(oldItem);
  updateNodes([
    {
      ...oldItem,
      ...newItem,
    },
  ]);
}

/**
 * Moves a node by updating its parent relationships
 * @param id ID of the node to move
 * @param parents Array of new parent system objects
 */
function moveNode(id: number, parents: Array<SystemObject>) {
  const state = nodes.getState();
  const node = state[id];

  if (node) {
    const oldParents = node.details?.parents || [];
    const newParents = parents.map((p) => findGraphNode(p.id)!);

    updateNode(id, (node) => ({
      details: {
        ...node.details,
        parents,
      },
    }));

    for (const parent of oldParents) {
      updateNode(parent.id, (node) => ({
        childNodes: node.childNodes.filter((c) => c !== id),
        details: {
          ...node.details,
          children: node.details.children?.filter((c) => c.id !== id),
        },
      }));
    }

    for (const parent of newParents) {
      updateNode(parent.id, (n) => ({
        childNodes: [...n.childNodes, id],
        details: {
          ...n.details,
          children: [...n.details.children, node.details],
        },
      }));
    }
  }
}

/**
 * Retrieves a graph node by ID, triggering a load if not found
 * @param id ID of the node to retrieve
 */
export function retrieveGraphNode(id: number): GraphNode {
  const state = nodes.getState();
  const node = state[id];

  if (!node) {
    loadGraphNode(id);
  }

  return node;
}

/**
 * Finds a graph node by ID without triggering a load operation
 * @param id ID of the node to find
 */
export function findGraphNode(id?: number): GraphNode | undefined {
  if (typeof id === 'number') {
    const state = nodes.getState();
    return state[id];
  }

  return undefined;
}

/**
 * Finds multiple graph nodes by their IDs
 * @param ids Array of node IDs to find
 */
export function findGraphNodes(ids: Array<number>): Array<GraphNode> {
  const state = nodes.getState();
  return ids.map((id) => state[id]).filter(Boolean);
}

/**
 * Gets all root node IDs from the graph
 */
export function getRootIds() {
  return Array.from(roots);
}

/**
 * Maps graph nodes to their system object representations
 * @param nodes Array of graph nodes to map
 */
export function mapSystemObjects(nodes: Array<GraphNode>) {
  return nodes.map((node) => node.details);
}

/**
 * Gets all root nodes as system objects
 */
export function getRootNodes() {
  const nodes = findGraphNodes(getRootIds());
  return mapSystemObjects(nodes);
}

/**
 * Queries node data from the server
 * @param id ID of the node to query
 * @param anon Whether to include hidden objects
 * @param key Query key to use (default: 'id')
 */
export async function queryNode(id: number, anon = false, key = 'id'): Promise<SystemObjectData> {
  if (typeof id !== 'number') {
    throw new Error('Received not valid id: ' + id);
  }

  return await requester.query<SystemObjectData>('co4CoreGIGet', { [key]: id, hidden: anon });
}

/**
 * Loads children of a graph node if not already loaded
 * @param id ID of the node whose children to load
 * @param anon Whether to include hidden objects
 */
export async function loadGraphChildren(id: number, anon = false) {
  const state = nodes.getState();
  const node = state[id];

  // load only if its not loaded and not loading and not all children are loaded
  if (!node.loaded && !node.loading) {
    await loadGraphNode(id, anon);
  }
}

/**
 * Completes a node by loading its full details and children
 * @param id ID of the node to complete
 * @param anon Whether to include hidden objects
 * @param key Query key to use (default: 'id')
 */
async function completeNode(id: number, anon = false, key = 'id') {
  const { object: details } = await queryNode(id, anon, key).catch((err) => {
    const deleted = !!err?.list?.some((m) => m?.message?.toLowerCase().includes('not found'));

    if (key === 'id') {
      updateNode(id, () => {
        return {
          ...toNode({ id, name: 'Loading...', natures: [] } as SystemObject),
          id,
          loaded: true,
          loading: false,
          deleted,
        };
      });
    }
    return { object: {} } as SystemObjectData;
  });

  const childNodes = details.children?.sort(sortNodes)?.map((m) => m.id);

  return {
    loaded: true,
    loading: false,
    details: constructSystemObject(details),
    childNodes,
  } as GraphNode;
}

/**
 * Completes multiple nodes by loading their details
 * @param loadedNodes Array of nodes to complete
 * @param anon Whether to include hidden objects
 */
export async function completeNodes(loadedNodes: Array<GraphNode>, anon = true) {
  const unloadedNodes = loadedNodes.filter((child) => !child.loaded && !child.loading);

  if (unloadedNodes.length > 0) {
    const initial = unloadedNodes.reduce((obj, node) => {
      obj[node.id] = {
        ...node,
        loading: true,
      };
      return obj;
    }, {});

    nodes.setState(initial);

    const newStates = await Promise.all(unloadedNodes.map((node) => completeNode(node.id, anon)));
    const snapshot = nodes.getState();
    const updated = unloadedNodes.map((node, i) => {
      const c = snapshot[node.id];
      const s = newStates[i];

      return {
        ...c,
        ...s,
      };
    });

    updateNodes(updated);
  }
}

/**
 * Loads a graph node by its nature instance ID
 * @param niid Nature instance ID
 * @param anon Whether to include hidden objects
 */
export async function loadGraphNodeByNiid(niid: number, anon = false) {
  return await loadGraphNode(niid, anon, 'niid');
}

/**
 * Loads a graph node by its ID or NIID
 * @param id ID of the node to load
 * @param anon Whether to include hidden objects
 * @param key Query key to use (default: 'id') - 'id' or 'niid'
 */
export async function loadGraphNode(id: number, anon = false, key = 'id') {
  if (key === 'id') {
    updateNode(id, () => ({
      loading: true,
    }));
  }

  const newState = await completeNode(id, anon, key);

  if (typeof newState?.details?.id === 'number') {
    updateNode(newState.details.id, () => newState);
  }
  return newState;
}

/**
 * Loads multiple graph nodes and their parents if necessary
 * @param ids Array of node IDs to load
 */
export function loadGraphNodes(ids: Array<number>) {
  const current: Array<GraphNode> = [];
  const snapshot = nodes.getState();
  const promises: Array<Promise<void>> = [];

  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    const node = snapshot[id];
    current.push(node);

    if (!node?.loaded && !node?.deleted) {
      if (!node?.loading) {
        // load the node
        promises.push(
          loadGraphNode(id, true).then((res) => {
            // load parents of the node if not loaded
            // (when node is visited directly using URL with missing nodes in the path)
            const parents = res?.details?.parents?.map((v) => v?.id) || [];
            loadGraphNodes(parents);
          }),
        );
      }

      current.push(
        ...ids.slice(i + 1).map(
          (id): GraphNode => ({
            id,
            loaded: false,
            deleted: false,
            loading: true,
            facts: {},
            childNodes: [],
            details: {
              id,
              hidden: false,
              name: '...',
              images: [],
              icon: undefined,
              natures: [],
              parents: [],
              path: [],
              properties: [],
              dependents: [],
            },
          }),
        ),
      );

      break;
    }
  }

  return current;
}

/**
 * Adds a new node to the graph with specified parents and properties
 * @param parents Array of parent IDs for the new node
 * @param input Partial system object details for the new node
 */
export async function addGraphNode(parents: Array<number>, input: Partial<Omit<SystemObjectDetails, 'parents'>>) {
  const properties = Object.entries(input)?.map(([label, value]) => ({
    label,
    value,
    access: PropertyAccess?.WRITE,
  }));
  const hidden = [undefined, null]?.includes(input?.name);

  const res = await requester.mutate<SystemObjectResult>('co4CreateSystemObject', {
    parents,
    hidden,
    properties,
  });

  let newChild: SystemObject;

  const parentPath = findGraphNode(parents[0])?.details?.path || [];

  if (res) {
    const path = parentPath.length ? parentPath.concat(res.object.id) : [];
    newChild = {
      ...input,
      name: input?.name || '',
      id: res?.object?.id,
      natures: [],
      path,
      hidden,
      properties: res?.object?.properties ?? [],
    };
  }

  for (const parent of parents) {
    const parentNode = findGraphNode(parent);
    const children = [...parentNode?.details.children]?.concat(newChild).sort((a, b) => a.name.localeCompare(b.name));

    updateNode(parent, (n) => ({
      loaded: n.loaded,
      loading: false,
      details: {
        ...parentNode?.details,
        children: parentNode?.details.children?.concat(newChild),
      },
      childNodes: children.map((c) => c.id),
    }));
  }

  return res;
}

/**
 * Maps a graph node to a dependent representation
 * @param node Graph node with optional blocker ID
 */
function mapDependent(node: GraphNode & { blockerId?: number }): Dependent {
  return {
    soid: node.details.id,
    name: node.details.name,
    icon: node.details.icon,
    nature: typeof node.blockerId === 'number' ? node.details.natures.find((n) => n.id === node.blockerId) : undefined,
  };
}

/**
 * Gets dependents for a node that would prevent its deletion
 * @param id ID of the node to check
 * @param prune Whether to include children in the check
 */
export async function getDependents(id: number, prune: boolean = false): Promise<Array<Dependent>> {
  const node = findGraphNode(id);
  const niids = node?.details?.natures?.map((n) => n.id) || [];

  // get the Nature Instance and System Object dependents
  const [niBlockers, soBlockers] = await Promise.all([
    canDeleteNatureInstances(niids),
    canDeleteGraphNodes([id], prune),
  ]);

  // load the dependent node details
  const ni = await Promise.all(
    niBlockers.map((id) => loadGraphNodeByNiid(id, true).then((res) => mapDependent({ ...res, blockerId: id }))),
  );

  // filter and only check for those SO blockers that are not in NI blockers
  const so = await Promise.all(
    soBlockers.filter((v) => !niBlockers.includes(v)).map((id) => loadGraphNodeByNiid(id, true)),
  )?.then((res) => res.map(mapDependent));

  return [...ni, ...so];
}

/**
 * Checks if nature instances can be deleted
 * @param niids Array of nature instance IDs to check
 * @returns Array of blocker IDs or empty array if deletion is possible
 */
export async function canDeleteNatureInstances(niids: Array<number>): Promise<Array<number>> {
  if (!niids.length) return [];

  const res = await requester.query<RemoveNodeResponse>('co4CanRemoveNatureInstances', { niids });
  return res?.blockers ?? [];
}

/**
 * Deletes nature instances associated with a system object.
 * Note: This implementation is designed for nature instances from a single system object.
 * It can be expanded in the future to handle nature instances from multiple system objects.
 * @param soid The system object ID
 * @param niids The nature instance IDs
 * @returns True if successful, or array of blocker IDs if deletion is not possible
 */
export async function deleteNatureInstances(soid: number, niids: Array<number>) {
  // check if the nature instances can be deleted
  const blockers = await canDeleteNatureInstances(niids);
  if (blockers.length > 0) return blockers;

  const res = await requester.mutate('co4RemoveNatureInstances', { niids });

  updateNode(soid, (prev) => ({
    details: {
      ...prev.details,
      natures: prev.details.natures?.filter((n) => !niids.includes(n.id)),
    },
  }));

  return res;
}

/**
 * Checks if graph nodes can be deleted
 * @param soids Array of system object IDs to check
 * @param prune Whether to include children in the check
 * @returns Array of blocker IDs or empty array if deletion is possible
 */
export async function canDeleteGraphNodes(soids: Array<number>, prune: boolean = false): Promise<Array<number>> {
  if (!soids.length) return [];

  const res = await requester.query<RemoveNodeResponse>('co4CanRemoveSystemObjects', { soids, prune });
  return res?.blockers ?? [];
}

/**
 * Deletes a graph node and its nature instances
 * @param id ID of the node to delete
 * @param prune Whether to delete children as well
 * @returns Array of blocker IDs if deletion fails, or the mutation result if successful
 */
export async function deleteGraphNode(id: number, prune: boolean = false) {
  const node = findGraphNode(id);

  // first, delete all the nature instances on the graph node
  // NOTE: Make sure that the dependents are unlinked before deleting the NIs, else it will fail
  const niids = node?.details?.natures?.map((n) => n.id) || [];

  if (niids.length) {
    const res = await deleteNatureInstances(id, niids);
    // if it has blockers, return the blockers
    if (Array.isArray(res) && res.length > 0) return res;
  }

  // delete the system object
  // NOTE: Make sure that the dependents are unlinked before deleting the node, else it will fail
  const res = await requester.mutate('co4RemoveSystemObjects', {
    soids: [id],
    prune,
  });

  if (node) {
    const parents = node.details?.parents || [];

    updateNode(id, (prev) => ({
      deleted: true,
      details: {
        ...prev.details,
        natures: [],
      },
    }));

    for (const parent of parents) {
      updateNode(parent.id, (p) => ({
        loaded: p.loaded,
        loading: p.loading,
        details: {
          ...p.details,
          children: p.details.children?.filter((c) => c.id !== id),
        },
        childNodes: p.childNodes.filter((c) => c !== id),
      }));
    }
  }

  return res;
}

/**
 * Loads a fact for a system object using the specified operation
 * @param soid System object ID
 * @param op Operation to execute
 * @param args Arguments for the operation
 * @param force Whether to force reload even if cached
 */
export async function loadFact<T>(
  soid: number | undefined,
  op: string,
  args: Record<string, number | string>,
  force = false,
) {
  const controller = updateController(`fact-${op}-${soid}`);
  const so = findGraphNode(soid);

  if (!force && so?.facts && op in so.facts) {
    return so.facts[op];
  }

  const result = await requester
    .query<T>(op, args, controller.signal)
    .finally(() => removeController(`fact-${op}-${soid}`));

  updateNode(soid, (node) => {
    if (!node) {
      return {
        id: soid,
        loaded: false,
        loading: false,
        childNodes: [],
        details: {
          id: soid,
          name: '...',
          images: [],
          parents: [],
          natures: [],
          path: node?.details?.path ?? [],
          properties: [],
          dependents: [],
          children: undefined,
          hidden: false,
          icon: undefined,
        },
        facts: {
          [op]: result,
        },
      };
    } else {
      return {
        facts: {
          ...node.facts,
          [op]: result,
        },
      };
    }
  });

  return result as T;
}

/**
 * Updates a fact for a system object by executing a mutation
 * @param soid System object ID
 * @param op Operation to execute
 * @param payload Payload for the mutation
 * @param clear Whether to clear the fact from cache after mutation
 */
export async function mutateFact<T>(
  soid: number | undefined,
  op: string,
  payload: Record<string, any>,
  clear: boolean = false,
) {
  const result = await requester.mutate<T>(op, payload);

  if (soid !== undefined) {
    let node = findGraphNode(soid);
    /* clear the facts if the "clear" is true */
    if (clear) {
      const facts = node?.facts ?? {};
      delete facts[op];

      updateNode(soid, (node) => ({
        details: {
          ...node.details,
          natures: node.details.natures?.filter((n) => n.id !== payload?.niid) ?? [],
        },
        facts,
      }));

      // get the updated node and update it in parents
      node = findGraphNode(soid);
      updateNodeInParents(soid, node?.details);
    } else if (!(op in node?.facts)) {
      /* If op doesn't exist in facts, refresh the natures */
      await resyncNode(soid);
    }
  }
  return result as T;
}

/**
 * Updates system object properties
 * @param id System object ID
 * @param details New property values
 */
async function updateProperties(id: number, details: Partial<SystemObjectDetails>) {
  const current = findGraphNode(id);
  const properties = current?.details?.properties ?? [];

  const newProps = [],
    updatedInputs = [],
    propsToRemove = [];

  Object.entries(details).forEach(([label, value]) => {
    if (!deepCompare(value, current?.details?.[label])) {
      const id = properties.find((p) => p.label === label)?.id;

      if (typeof id === 'number') {
        if (value === undefined || value === null) {
          propsToRemove.push(id);
        } else {
          updatedInputs.push([id, value]);
        }
      } else if (value !== undefined && value !== null) {
        newProps.push({
          label,
          value,
          access: PropertyAccess.WRITE,
        });
      }
    }
  });

  if (propsToRemove.length > 0) {
    await requester.mutate('removeObjectProperties', { ids: propsToRemove });
  }

  if (newProps.length > 0) {
    await requester.mutate('setObjectProperties', { soid: id, properties: newProps });
  }

  if (updatedInputs.length > 0) {
    let ids = [],
      values = [];

    for (let [id, value] of updatedInputs) {
      ids.push(id);
      values.push(value);
    }

    await requester.mutate('updateObjectProperties', { ids, values });
  }
}

/**
 * Updates a graph node with new details and parent relationships
 * @param id ID of the node to update
 * @param input New system object details
 */
export async function updateGraphNode(id: number, input: Partial<SystemObjectDetails>) {
  const { parents = [], path, ...rest } = input;
  const current = findGraphNode(id);

  if (!!current?.details?.name !== !!input?.name) {
    await requester.mutate('co4UpdateSystemObject', { id, hidden: !input?.name });
  }

  const currentPIds = current?.details?.parents?.map(({ id }) => id) || [];
  const pIds = parents.map((p) => p?.id);
  const parentsChanged = !deepCompare(currentPIds, pIds);

  if (parentsChanged) {
    await requester
      .mutate('co4MoveSystemObject', { id, parents: parents.map((p) => p?.id) })
      .then(() => moveNode(id, parents));
  }

  const res = await updateProperties(id, rest);

  updateNode(id, (state) => ({
    details: {
      ...state.details,
      ...rest,
      labels: rest?.labels ?? [],
      parents,
    },
  }));

  return res;
}

/**
 * Extracts system object references from a URL segment
 * @param segment URL segment containing hexadecimal IDs
 */
export function getSystemObjectRefs(segment: string): Array<number> {
  return segment
    .split('-')
    .map((x) => parseInt(x, 16))
    .filter((x) => !isNaN(x));
}

/**
 * Creates a URL segment from system object references
 * @param objects Array of system object references
 */
export function getSystemObjectLink(objects: Array<SystemObjectRef>) {
  return objects.map((obj) => obj.id.toString(16)).join('-');
}

/**
 * Gets a URL segment for the root node trail
 */
export function getRootTrail() {
  const [root] = roots;
  return root.toString(16);
}

/**
 * Gets the path for an object by ID or nature instance ID
 * @param id System object ID
 * @param niid Nature instance ID
 */
export async function getObjectPath(id?: number, niid?: number) {
  if (typeof id !== 'number' && typeof niid !== 'number') {
    return '';
  }

  const object = findGraphNode(id);
  const path = object?.details?.path;

  if (path?.length) {
    return formatObjectPath(path);
  } else {
    const { path } =
      (await requester.query<{ path: number[] }>('co4GetObjectPath', { id: id ?? null, niid: niid ?? null })) ?? {};
    return formatObjectPath(path);
  }
}

/**
 * Formats an object path array into a URL-friendly string
 * @param path Array of system object IDs representing a path
 */
export function formatObjectPath(path: Array<number>) {
  return path.map((p) => p.toString(16)).join('-');
}
