import 'regenerator-runtime/runtime';

import * as React from 'react';
import * as names from './constants';

import { run } from '@co4/base';
import { LoadingIndicator } from '@co4/components';
import { getRootTrail } from '@co4/graph';
import { type InitialTile, type TileComponentProps } from 'piral-dashboard';
import { Redirect } from 'react-router-dom';
import { apiUrl, appEnv, feedUrl } from './generated/config.codegen';
import { client } from './oauth';
// import './sentry';

// disable the piral state view by default in console
sessionStorage.setItem('dbg:view-state', 'off');

if (process.env.DEBUG_PILET === 'on') {
  // default behavior is "off", but we want to load pilets from the included feed
  sessionStorage.setItem('dbg:load-pilets', 'on');
}

const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Layout = React.lazy(() => import('./components/Layout'));
const Greetings = React.lazy(() => import('./tiles/GreetingsTile'));

function makeTile(
  component: React.ComponentType<TileComponentProps>,
  initialColumns: number,
  initialRows: number,
): InitialTile {
  return {
    component,
    preferences: {
      initialColumns,
      initialRows,
    },
  };
}

async function init() {
  try {
    const { shouldRender } = await client.handleAuthentication();

    if (shouldRender) {
      await run({
        client,
        feedUrl,
        apiUrl,
        appEnv,
        Layout: ({ children }) => (
          <React.Suspense fallback={<LoadingIndicator />}>
            <Layout>{children}</Layout>
          </React.Suspense>
        ),
        tiles: [makeTile(() => <Greetings />, 16, 4)],
        sidebars: {},
        routes: {
          // temporary transitioning routes for DM2. Remove once app use is stable.
          [names.objectsDetailsAppPath]: (props) => (
            <Redirect to={names.transitioningRoute(props.match.params)} {...props} />
          ),
          // permanent routes - order matters
          [names.objectsRootPath]: (props) => (
            <Redirect to={names.objectsArgsSubPath(names.DEFAULT_APP, getRootTrail(), '', '')} {...props} />
          ),
          [names.objectsTrailPath()]: (props) => (
            <Redirect to={names.objectsArgsSubPath(names.DEFAULT_APP, getRootTrail(), '', '')} {...props} />
          ),
          // [names.objectsArgsPath()]: (props) => (
          //   <Dashboard {...props} app={props.match.params.app} trail={props.match.params.trail} />
          // ),
          [names.objectsArgsSubPath()]: (props) => (
            <Dashboard {...props} app={props.match.params.app} trail={props.match.params.trail} />
          ),
        },
      });
    }
  } catch (error) {
    console.error(error);

    await client.logout();
  }
}

init();
