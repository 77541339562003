import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DeepPartial } from '../types';
import { useHighchartsResizeObserver } from '../hooks';

export interface StackedChartProps {
  data: Array<Highcharts.SeriesColumnOptions>;
  labels: Array<string>;
  axis?: DeepPartial<{
    x: {
      title: string;
    };
    y: {
      title: string;
    };
  }>;
  plotOptions?: Highcharts.Options['plotOptions'];
  config?: Highcharts.Options;
}

export const StackedChart: React.FC<StackedChartProps> = ({ data, labels, axis, plotOptions = {}, config = {} }) => {
  const { chartRef, containerRef } = useHighchartsResizeObserver();

  const highChartsData: Highcharts.Options = {
    ...config,
    chart: {
      type: 'column',
      spacing: [0, 0, 0, 0],
      height: 300,
      backgroundColor: 'transparent',
      marginTop: 12,
      reflow: true,
      ...(config?.chart ?? {}),
    },
    colors: ['#59B593', '#377289', '#C58293'],
    credits: { enabled: false },
    plotOptions: {
      ...plotOptions,
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        ...(plotOptions?.series ?? {}),
      },
      column: {
        pointWidth: 10,
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
        borderWidth: 0,
        states: {
          hover: {
            enabled: false,
          },
        },
        ...(plotOptions?.column ?? {}),
      },
    },
    legend: { enabled: false, floating: false, align: 'right', verticalAlign: 'top', ...(config?.legend ?? {}) },
    series: data,
    title: { text: undefined, ...(config?.title ?? {}) },
    xAxis: { categories: labels, ...(config?.xAxis ?? {}) },
    yAxis: {
      title: { text: axis?.y?.title ?? '' },
      endOnTick: false,
      ...(config?.yAxis ?? {}),
    },
  };

  return (
    <div className="bar-chart" ref={containerRef}>
      <HighchartsReact highcharts={Highcharts} options={highChartsData} ref={chartRef} />
    </div>
  );
};
