export interface SystemObject {
  id: number;
  name: string;
  hidden: boolean;
  natures: Array<NatureInstance>;
  properties: Array<Property>;
  children?: Array<SystemObject>;
  parents?: Array<SystemObject>;
  path?: Array<number>;
}

export interface NatureDefinition {
  id: number;
  code: string;
  icon?: string;
  characteristics?: Array<CharacteristicRef>;
  parents: Array<Pick<NatureDefinition, 'id'>>;
}

export interface NavRoots {
  roots: Array<SystemObject>;
}

export interface SystemObjectRef {
  id: number;
}

export interface CharacteristicRef {
  code: string;
}

export interface IconReference {
  label: string;
  library: string;
  name: string;
}

export interface SystemObjectDetails extends SystemObject {
  description?: string;
  images?: Array<{ id: number; image: string }> | null;
  icon?: IconReference;
  labels?: Array<string>;
  dependents?: Array<Partial<SystemObject>>;
}

export interface SystemObjectData {
  object: SystemObject;
}

export interface GraphNode {
  id: number;
  loaded: boolean;
  loading: boolean;
  deleted: boolean;
  details: SystemObjectDetails;
  facts: Record<string, any>;
  childNodes: Array<number>;
}

export interface SystemObjectResult {
  object: {
    id: number;
    properties: Array<Property>;
  };
}

export interface CategorizedObjects {
  id: number;
  natureTitle: string;
  objects: Array<GraphNode>;
}

export enum PropertyAccess {
  READ = 'READ',
  WRITE = 'WRITE',
  PRIVATE = 'PRIVATE',
}

export interface PropertyInput {
  access?: PropertyAccess;
  iri?: string;
  label: string;
  value?: string | number | Record<string, any> | null;
}

export interface Property extends PropertyInput {
  id: number;
  object: SystemObject;
}

export interface NatureInstanceField {
  value: Array<NatureInstance>;
  source: NatureInstance;
  created: string;
}

export interface NatureInstance {
  id: number;
  object: SystemObject;
  nature: NatureDefinition;
  parents: Array<NatureInstance>;
  children: Array<NatureInstance>;
  dependents: Array<NatureInstanceField>;
  remove: string;
}

export interface Dependent {
  soid: number;
  /**
   * Only exists if the dependent is a nature instance.
   */
  nature?: NatureInstance;
  name: string;
  icon: IconReference;
}

export interface RemoveNodeResponse {
  /**
   * True if the nature instances can be removed, false otherwise.
   */
  allowed: boolean;
  /**
   * The list of nature instances or system objects are blocking the removal. These are dependent on the node.
   */
  blockers: Array<number>;
}
