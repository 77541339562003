import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CategorizedObjects, GraphNode } from './types';
import {
  completeNodes,
  findGraphNode,
  getDependents,
  getSystemObjectLink,
  getSystemObjectRefs,
  loadFact,
  loadGraphNode,
  loadGraphNodes,
  natures,
  nodes,
} from './graph';

/**
 * Hook to get selected system objects based on the current URL trail parameter.
 */
export function useSelectedSystemObjects() {
  const { trail = '' } = useParams<{ trail: string }>();
  return useNodes(trail);
}

/**
 * Hook to get graph nodes based on a trail string.
 */
export function useNodes(trail: string) {
  const [selected, setSelected] = useState<Array<GraphNode>>([]);

  useEffect(() => {
    const refs = getSystemObjectRefs(trail);
    setSelected(loadGraphNodes(refs));

    return nodes.subscribe((current, previous) => {
      if (refs.some((id) => previous[id] !== current[id])) {
        setSelected(loadGraphNodes(refs));
      }
    });
  }, [trail]);

  return selected;
}

/**
 * Helper function to check if a trail string contains invalid segments
 */
function hasInvalidTrailSegments(trail: string): boolean {
  if (!trail) return false;
  
  // Original segments count
  const originalSegments = trail.split('-');
  
  // Valid segments are hex numbers
  const validSegments = originalSegments.filter(segment => {
    // Must be a valid hex number (no letters beyond 'f')
    return /^[0-9a-f]+$/i.test(segment);
  });
  
  // If there's a mismatch, we have invalid segments
  return originalSegments.length !== validSegments.length;
}

interface PathValidationResult {
  isLoading: boolean;
  shouldRedirect: boolean;
  isValid: boolean;
  redirectPath: string;
}

/**
 * Custom hook to validate path parameters and handle redirections
 * for invalid or deleted nodes
 */
export function usePathValidation(
  nodes: GraphNode[],
  node: GraphNode | undefined,
  history: any,
  makePathFn: (app: string, trail: string) => string,
  app: string = 'gi', // Default to 'gi' but allow overriding
  timeout: number = 3000,
): PathValidationResult {
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectPath, setRedirectPath] = useState('');
  const isDeleted = node?.deleted;
  const { trail = '' } = useParams<{ trail: string }>();

  // Detect malformed trail immediately - before even loading
  useEffect(() => {
    if (hasInvalidTrailSegments(trail)) {
      // Filter for only valid hex segments
      const validSegments = trail.split('-').filter(segment => /^[0-9a-f]+$/i.test(segment));
      // Create a clean trail
      const validTrail = validSegments.join('-');
      
      if (validTrail) {
        setRedirectPath(makePathFn(app, validTrail));
        setShouldRedirect(true);
      } else {
        setRedirectPath('/');
        setShouldRedirect(true);
      }
    }
  }, [trail, app, makePathFn]);

  // Handle loading state and timeout detection
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, timeout);

    // Clear timeout if nodes load successfully
    if (nodes.length > 0 && nodes.every((n) => n && n.details && n.id !== undefined)) {
      setIsLoading(false);
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [nodes, timeout]);

  // Handle deleted node via useEffect
  useEffect(() => {
    if (isDeleted) {
      const updatedNodes = [...nodes];
      updatedNodes.pop();
      const newPath = makePathFn(app, getSystemObjectLink(updatedNodes));
      setRedirectPath(newPath);
      setShouldRedirect(true);
    }
  }, [isDeleted, nodes, makePathFn, app]);

  // Handle invalid nodes via useEffect - for nodes that attempted to load but failed
  useEffect(() => {
    if (!shouldRedirect && !isLoading && (!nodes.length || !node || !node.details || 
      nodes.some(n => !n || !n.details || n.id === undefined))) {
      // Filter to only keep valid nodes that have actually loaded
      const validNodes = nodes.filter(n => n && n.details && n.id !== undefined);
      
      // Check if we have valid nodes but fewer than the original array
      if (validNodes.length > 0) {
        // Create a new path with only valid nodes
        const newPath = makePathFn(app, getSystemObjectLink(validNodes));
        setRedirectPath(newPath);
        setShouldRedirect(true);
      } else {
        // No valid nodes at all, redirect to root
        setRedirectPath('/');
        setShouldRedirect(true);
      }
    }
  }, [isLoading, nodes, node, makePathFn, app, shouldRedirect]);

  // Perform the actual redirection
  useEffect(() => {
    if (shouldRedirect && redirectPath) {
      history.replace(redirectPath);
      // Reset the shouldRedirect flag after redirection is triggered
      setShouldRedirect(false);
    }
  }, [shouldRedirect, redirectPath, history]);

  return {
    isLoading,
    shouldRedirect,
    isValid: !isLoading && !shouldRedirect && !!node?.details,
    redirectPath,
  };
}

/**
 * Hook to load and retrieve a specific fact for a system object.
 */
export function useFact(soid: number, niid: number, op: string, force: boolean = false) {
  const node = useNode(soid);

  useEffect(() => {
    if (!node || !(op in node.facts)) {
      loadFact(soid, op, { niid }, force);
    }
  }, []);

  return node?.facts[op];
}

/**
 * Hook to get a graph node by ID.
 */
export function useNode(id?: number) {
  const [node, setNode] = useState(findGraphNode(id));

  useEffect(() => {
    if (typeof id === 'number') {
      if (node?.id !== id) {
        setNode(findGraphNode(id));
      }

      return nodes.subscribe((current, previous) => {
        const next = current[id];
        const prev = previous[id];

        if (next !== prev) {
          setNode(next);
        }
      });
    }
  }, [id]);

  return node;
}

/**
 * Hook to get a loaded graph node, triggering a load if necessary.
 */
export function useLoadedNode(id: number, anon: boolean = false) {
  const child = useNode(id);

  useEffect(() => {
    if (!child || (!child.loaded && !child.loading)) {
      loadGraphNode(id, anon);
    }
  }, [child]);

  return child;
}

/**
 * Hook to get all loaded graph nodes, optionally completing their data.
 */
export function useLoadedNodes(anon: boolean = false, load: boolean = true) {
  const [loadedNodes, setLoadedNodes] = useState(() => Object.values<GraphNode>(nodes.getState()));

  useEffect(() => {
    if (load) {
      completeNodes(loadedNodes, anon);
    }
  }, [loadedNodes]);

  useEffect(() => {
    return nodes.subscribe((m) => {
      setLoadedNodes(Object.values(m));
    });
  }, []);

  return loadedNodes;
}

/**
 * Hook to filter nodes based on search input, anonymity setting, and nature display.
 */
export function useFilteredNodes(input: string, anonymous: boolean, display: string) {
  const loadedNodes = useLoadedNodes(anonymous, false);

  return useMemo(() => {
    let result = loadedNodes;

    if (input) {
      const tokens = input.toLowerCase().trim().split(/\W+/);
      result = result.filter((m) => {
        const n = m.details?.name;

        if (n) {
          const s = n.toLowerCase();
          return tokens.some((t) => s.includes(t));
        }

        return false;
      });
    }

    const nature = natures.find((m) => m.code === display);

    if (nature) {
      const nid = nature.id;
      result = result.filter((child) => child.details.natures.some((m) => m.id === nid));
    }

    return result;
  }, [input, loadedNodes, display]);
}

/**
 * Hook to get the current system object (usually the last one in the trail).
 */
export function useCurrentSystemObject() {
  const selected = useSelectedSystemObjects();
  return selected.at(-1);
}

/**
 * Hook to get the parent nodes of a given graph node.
 */
export function useParents(node: GraphNode) {
  const [parents, setParents] = useState<Array<GraphNode>>([]);

  useEffect(() => {
    const details = node.details;
    const tasks: Array<Promise<GraphNode>> = [];
    const parents = details.parents.map((p) => {
      const node = findGraphNode(p.id);

      if (!node) {
        tasks.push(loadGraphNode(p.id));
      }

      return node;
    });

    if (tasks.length) {
      Promise.all(tasks).then(() => setParents(details.parents.map((p) => findGraphNode(p.id)!)));
    }

    setParents(parents.filter(Boolean).map((p) => p!));
  }, [node]);

  return parents;
}

/**
 * Hook to categorize graph nodes by their nature types.
 */
export function useCategorizedGraph(soids: Array<number>): Array<CategorizedObjects> {
  return useMemo(() => {
    if (soids.length) {
      const objects = soids.map(findGraphNode);

      const natureIds = [
        ...new Set(
          objects
            .filter(Boolean)
            .map((ob) => ob!.details?.natures)
            ?.flat()
            ?.map((d) => d?.nature?.id) ?? [],
        ),
      ];

      //Filtering by Nature Types
      const categories = natureIds.map((id) => {
        const natureTitle = natures.find((n) => n.id === id)?.code.replace(/_/g, ' ') ?? 'unknown';
        return {
          id,
          natureTitle,
          objects: objects.filter((ob) => ob!.details.natures?.some((n) => id === n?.id)).map((n) => n!),
        };
      });

      //The Objects with no nature types goes to unassigned
      const unassignedObjects = objects
        .filter((ob) => !ob!?.details?.natures?.some((n) => natureIds.includes(n?.id)))
        .map((n) => n!);

      if (unassignedObjects.length) {
        categories.push({
          id: 0,
          natureTitle: 'unassigned',
          objects: unassignedObjects,
        });
      }

      return categories;
    }

    return [];
  }, [soids]);
}

/**
 * Hook to get dependents of a system object.
 */
export function useDependents(id: number, refresh: boolean = false) {
  const node = useNode(id);
  const deps = node?.details?.dependents ?? [];
  const [dependents, setDependents] = useState(deps);

  useEffect(() => {
    if (typeof id === 'number' && (refresh || !deps.length)) {
      getDependents(id).then(setDependents);
    }
  }, [id, refresh, deps.length]);

  return dependents;
}
