import HighchartsReact from 'highcharts-react-official';
import { useEffect, useRef } from 'react';

/**
 * Custom hook to observe and handle Highcharts resize events.
 * Bind the refs to the container and chart to observe resize events.
 * @returns {{ containerRef: React.RefObject<HTMLDivElement>, chartRef: React.RefObject<HighchartsReact.RefObject> }} - Refs for the container and chart.
 */

export const useHighchartsResizeObserver = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const chartContainer = chartRef.current?.container?.current;

    const resizeObserver = new ResizeObserver(() => {
      if (chartContainer) {
        const c = chartContainer?.getElementsByClassName('highcharts-container')[0];
        const svg = chartContainer?.getElementsByTagName('svg')[0];
        const bg = chartContainer?.getElementsByClassName('highcharts-background')[0];

        svg.style.setProperty('width', '100%');
        // @ts-ignore
        c.style.setProperty('width', '100%');
        // @ts-ignore
        bg.style.setProperty('width', '100%');
      }

      if (chartRef.current) {
        chartRef.current.chart.reflow();
      }
    });

    resizeObserver.observe(container);
    return () => resizeObserver.disconnect();
  }, [chartRef.current, containerRef.current]);

  return { containerRef, chartRef };
};
